import React from 'react';
import { Link,StaticQuery, graphql} from 'gatsby';
import { Fade } from 'react-awesome-reveal';

const relatedBlogsTemplate  = () => (
    <StaticQuery query={graphql`
    {   
            allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "blog"}}}}, sort: {fields: date, order: DESC}, limit: 3) {
                edges {
                   node {
                         id
                         slug
                         path
                         status
                         template
                         format
                         title
                         content
                         date(formatString: "DD, MMM, YYYY")
                         acf {
                          intro
                          tagline
                          client_name
                          project_name
                          featured_
                          featured_ordering
                          intro_text
                          thumbnail_image {
                            localFile {
                              publicURL
                              childImageSharp {
                                fluid {
                                  srcWebp
                                  srcSetWebp
                                  originalImg
                                  originalName
                                }
                              }
                            }
                          }
                        }
                        
                     }
                   
                }
             }
    }
    `} render={props => (
      
        <Fade triggerOnce="true" direction="top" className="article-section">
			<div className="container">
				<div className="border-top py-8 py-md-10 py-lg-12">
					<h2 className="section-heading text-default text-center mb-5 mb-md-13 fw-700">Related articles</h2>
						<div className="article-list position-relative row">
							
                            {props.allWordpressPost.edges.map((post,key) => (
								<div className="col-12 col-md-6 col-lg-4 position-relative mb-5 mb-md-0">
									<div className="card">
										<Link to={`/blog/${post.node.slug}`} className="card-img-top d-block position-relative">
										
										<img src={`${post.node.acf.thumbnail_image.localFile.publicURL}`} alt="" width="315" className="rounded" />
										</Link>
										<div class="card-body">
											<h3 class="card-title fw-700"><Link to={`/blog/${post.node.slug}`}>{post.node.acf.tagline}</Link></h3>
											<p class="card-text">{post.node.acf.intro_text}</p>
										</div>	
									</div>
								</div>
								))}
						</div>
						<div className="row align-items-center justify-content-between mb-15">
							<div className="col-md-12 text-center">
							<a href="/blog" className="btn btn-primary">Read more News &amp; Insights</a>
							</div>
						</div>
				</div>
			</div>
		</Fade>
        )} />

    );
        

export default relatedBlogsTemplate;
